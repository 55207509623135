.vertical {
    width: 2px;
    left: 5.8%;
    height: calc(100% - 32px);
    background-color: #eee;
    position: absolute;
    z-index: 1;
    bottom: 0;
}

.comment {
    position: relative;
    width: 100%;
}
.comment .pComment {
    padding: 10px;
    font-size: 16px;
    line-height: 1.7;
    position: relative;
    width: 87%;
    background-color: #ddd;
    border-radius: 4px;
}
.comment .top-40 {
    margin-top: 40px;
}
.bottom-40 {
    margin-top: 40px;
}
.comment .pComment:nth-child(odd) {
    left: 13%;
}
.comment .pComment:nth-child(even) {
    left: 13%;
}
@media all and (max-width: 1360px) {
    .comment .pComment:nth-child(odd) {
        left: 12.5%;
    }
    .comment .pComment:nth-child(even) {
        left: 12.5%;
    }
}
@media all and (min-width: 1600px) {
    .comment .pComment:nth-child(odd) {
        left: 13.2%;
    }
    .comment .pComment:nth-child(even) {
        left: 13.2%;
    }
}
@media all and (min-width: 1690px) {
    .comment .pComment:nth-child(odd) {
        left: 13.65%;
    }
    .comment .pComment:nth-child(even) {
        left: 13.65%;
    }
}
.comment .pComment:nth-child(odd)::before,
.comment .pComment:nth-child(even)::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    display: block;
    border: 3px solid #fff;
    border-radius: 50%;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 1px 0px rgba(0, 0, 0, 0.2);

    background-color: rgba(27, 110, 194, 1);
    top: 40%;
    left: -11.2%;
    z-index: 2;
}
.comment .pComment:nth-child(odd)::before {
    right: 107.5%;
}
.comment .pComment:nth-child(even)::before {
    right: 107.5%;
}
.comment .pComment:nth-child(odd)::after,
.comment .pComment:nth-child(even)::after {
    content: '';
    position: absolute;
    border: solid 8px;
    top: 42%;
}
.comment .pComment:nth-child(odd)::after {
    right: 100%;
    border-color: transparent #6c757d transparent transparent;
}
.comment .pComment:nth-child(even)::after {
    right: 100%;
    border-color: transparent #6c757d transparent transparent;
}
