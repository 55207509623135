﻿$system-tag-background-color: #ffdea7;

.tagify {
    --tag-readonly-bg: $system-tag-background-color;
}

.tags {
    border: none;
}

.tagify__input {
    display: none;
}

.tagify__dropdown {
    line-height: 1;
}

.tagify--loading::after {
    content: "";
    vertical-align: middle;
    opacity: 1;
    width: .7em;
    height: .7em;
    border: 3px solid;
    border-color: #eee #bbb #888 transparent;
    border-radius: 50%;
    animation: rotateLoader .4s infinite linear;
    content: "" !important;
    margin: auto 0 auto 10px;
}

.tagify__tag[readonly] > div::before {
    background-color: $system-tag-background-color;
    box-shadow: none;
    filter: none;
    content: "";
    position: absolute;
    border-radius: inherit;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    pointer-events: none;
    transition: 120ms ease;
    color: #fff;
    box-shadow: 0 0 0 1.1em $system-tag-background-color inset;
    box-shadow: 0 0 0 var(--tag-inset-shadow-size, 1.1em) var(--tag-readonly-bg, $system-tag-background-color) inset;
}

.tags-readonly-container {
    padding: 4px;
}

.tags-readonly-container .tag {
    float: left;
    padding: 3px 6px;
    margin: 4px 4px 4px 4px;
    background-color: #E5E5E5;
    border-radius: 3px;
}

.tags-readonly-container .tag.system-tag {
    background-color: $system-tag-background-color;
}