@import 'src/styles/site.scss';
@import 'src/styles/_timeline.scss';

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

#root {
    min-height: 100vh;
    margin: 0;

    display: grid;
    grid-template-rows: auto 1fr auto;
}

.text-secondary {
    color: rgba(108, 117, 125, 1);
}
p {
    margin: 0;
}
h1 {
    margin: 0;
}
.backgroundColorFa {
    background: #fafafa;
}

.ic-re-open {
    border: 4px solid $gray-100;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    background-color: $gray-200;
    cursor: pointer;
}
.claimInputGroup #input-group-dropdown-1 {
    min-width: 80px;
    border: 1px solid var(--bs-border-color);
    background: var(--bs-body-bg);
    color: #6c767d;
}
.nav-tabs .nav-link.active {
    color: var(--bs-nav-link-color);
}
.nav-tabs .nav-link {
    color: #000000;
}
.nav-underline .nav-link.active {
    background-color: #f8f9fa;
    color: $primary;
    font-weight: 400;
}
.nav-underline .nav-link {
    background-color: #f8f9fa;
    color: rgba(108, 117, 125, 1);
    font-weight: 400;
}

// Base input file
.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.input-file {
    border-radius: 0 4px 4px 0;
}

.button-upload {
    cursor: pointer;
    border-radius: 4px 0px 0px 4px;
    background-color: $gray-100;
    border-color: $gray-300;
    border-right: transparent;
}

.upload-btn-wrapper input[type='file'] {
    cursor: pointer;
    height: 35px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}
.cursor-pointer {
    cursor: pointer;
}
.cursor-not-allowed {
    cursor: not-allowed;
}
.containerLoading .modal-content {
    background-color: transparent;
    border: none;
}
.containerSpinner .spinner-border {
    color: #fff;
}
.sizeModal .modal-dialog {
    max-width: 550px;
}

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.total-value-tooltip {
    margin: 0;
    td {
        padding: 2px 4px;
        background-color: transparent;
        color: white;
        border-bottom: none;
    }
    tr td:first-child {
        padding-left: 0;
    }
    tr td:last-child {
        padding-right: 0;
    }
}

.tooltip {
    position: fixed;
}

.custom-width-modal {
    max-width: 650px;
}

.payment-tab-item.active {
    border-bottom-color: white !important;
}
