﻿@import 'variables';

.modal.contravention-timeline {

    .modal-dialog {
        position: fixed;
        margin: auto;
        width: 500px;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
    }

    .modal-content {
        height: 100%;
        overflow-y: auto;
        border-radius: 0;
        border: none;
    }

    .modal-header {
        border-bottom-color: #EEEEEE;
        background-color: #FAFAFA;
    }

    .modal-body {
        padding: 30px 20px 80px 20px;
        position: relative;

        .contravention-timeline-track {
            position: absolute;
            left: 65px;
            top: 0;
            bottom: 0;
            width: 10px;
            background-color: #f6f6f6;
            z-index: 1061;
        }

        .timeline-date {
            display: flex;
            margin-bottom: 15px;

            .event-date {
                flex: 0 0 100px;
                align-self: flex-start;
                z-index: 1062;
                padding: 10px;
                background-color: #6192ab;
                color: #fff;
                text-align: center;
            }

            .events-container {
                flex-grow: 100;
                margin-left: 15px;

                .event-detail {
                    position: relative;
                    background-color: #e5e5e5;
                    margin-bottom: 5px;
                    padding: 10px;

                    &:first-child::after {
                        position: absolute;
                        top: 10px;
                        left: -10px;
                        content: "";
                        border-top: 10px solid transparent;
                        border-right: 10px solid #e5e5e5;
                        border-bottom: 10px solid transparent;
                    }
                }
            }
        }
    }

    &.fade {
        .modal-dialog {
            right: -500px;
            -webkit-transition: opacity 0.2s linear, right 0.2s ease-out;
            -moz-transition: opacity 0.2s linear, right 0.2s ease-out;
            -o-transition: opacity 0.2s linear, right 0.2s ease-out;
            transition: opacity 0.2s linear, right 0.2s ease-out;
        }

        &.show {
            .modal-dialog {
                right: 0;
            }
        }
    }
}

.modal.contravention-actions {

    .modal-dialog {
        position: fixed;
        margin: auto;
        width: 500px;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
    }

    .modal-content {
        height: 100%;
        overflow-y: auto;
        border-radius: 0;
        border: none;
    }

    .modal-header {
        border-bottom-color: #EEEEEE;
        background-color: #FAFAFA;
    }

    .modal-body {
        padding: 30px 20px 80px 20px;
        position: relative;

        .contravention-timeline-track {
            position: absolute;
            left: 65px;
            top: 0;
            bottom: 0;
            width: 10px;
            background-color: #f6f6f6;
            z-index: 1061;
        }
    }

    &.fade {
        .modal-dialog {
            right: -500px;
            -webkit-transition: opacity 0.2s linear, right 0.2s ease-out;
            -moz-transition: opacity 0.2s linear, right 0.2s ease-out;
            -o-transition: opacity 0.2s linear, right 0.2s ease-out;
            transition: opacity 0.2s linear, right 0.2s ease-out;
        }

        &.show {
            .modal-dialog {
                right: 0;
            }
        }
    }
}

.issue-correspondence-modal {
    .correspondence-attached-document {
        border: 1px solid $ukpcbluelight;
        padding: 5px;
        margin-bottom: 2px;
        background-color: #fff;
        cursor: move;

        &:hover {
            background-color: lighten($ukpcbluelight, 30%);
        }
    }
}

div.location-contravention-reasons {
    width: 100%;
    overflow: auto;
    /*display: flex;
    flex-direction: column;
    flex-grow: 1;*/

    table {
        width: auto;
        white-space: nowrap;
        margin: 0;
        border: none;
        border-collapse: separate;
        border-spacing: 0;
    }

    table td,
    table th {
        border: 1px solid #bababa;
        padding: 0.5rem 1rem;
        min-width: 200px !important;
    }

    table thead th {
        padding: 3px;
        position: sticky;
        top: 0;
        z-index: 1;
        background: white;
        width: 300px !important;
    }

    table td {
        background: #fff;
        padding: 4px 5px;
        text-align: center;
    }

    table tbody th {
        font-weight: 100;
        font-style: italic;
        text-align: left;
        position: relative;
    }

    table thead th:first-child,
    table tbody td:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
        text-align: left;
    }

    table thead th:first-child {
        z-index: 3;
    }

    table tbody th {
        position: sticky;
        left: 0;
        background: white;
        z-index: 1;
    }

    .toggle-handle {
        display: none;
    }

    .toggle-off.btn {
        padding-left: initial !important;
        padding-right: initial !important;
    }

    .toggle-on.btn {
        padding-left: initial !important;
        padding-right: initial !important;
    }

    input[type="checkbox"] {
        display: none;
    }

    td.disabled {
        background-color: #dcdcdc;

        * {
            display: none;
        }
    }
}

#AccountTransactionsList {
    tbody td {
        vertical-align: middle;
    }
}
