﻿@import 'variables';
@import 'forms';
@import 'tags';
@import 'calendar';
@import 'contraventions';
@import 'typeahead';
@import 'typography.scss';
@import '~bootstrap/scss/bootstrap';

a,
.btn-link {
    text-decoration: none !important;
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.border-top {
    border-top: 1px solid #e5e5e5;
}

.border-bottom {
    border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

button.accept-policy {
    font-size: 1rem;
    line-height: inherit;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
    position: relative;
    min-height: 100%;
}

body {
    /* Margin bottom by footer height */
    // margin-bottom: 60px;
}

main {
    // margin-bottom: 60px;
}

.footer {
    width: 100%;
    white-space: nowrap;
    line-height: 60px; /* Vertically center the text there */
}

header {
    margin-bottom: 20px;
}

#ToastContainer {
    position: fixed;
    top: 70px;
    right: 20px;
    z-index: 1100;
}

.btn-save {
    @include button-variant($ukpcbluedark, darken($ukpcbluedark, 7.5%));
}

.btn-edit {
    @include button-variant($ukpcbluelight, darken($ukpcbluelight, 7.5%));
}

.btn-create {
    @include button-variant($ukpcbluedark, darken($ukpcbluedark, 7.5%));
}

.breadcrumb-container {
    border: 1px solid silver;
    /*@include border-radius($breadcrumb-border-radius);*/
    padding: 15px !important;
}

.dropzone {
    border: 1px dashed grey;
}

.dz-drag-hover {
    border: 1px dashed steelblue !important;
    background-color: rgba(70, 130, 180, 0.1) !important;
}

.sign-file-container {
    border: 1px solid #555;
    overflow: hidden;

    .sign-file-preview {
        position: relative;
        height: 300px;
        width: 100%;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            height: 280px;
            object-fit: contain;
        }

        label.sign-file-primary {
            position: absolute;
            top: 5px;
            left: 5px;
            background-color: forestgreen;
            padding: 5px 10px;
            color: #fff;
        }

        .sign-file-options-menu {
            position: absolute;
            top: 5px;
            right: 5px;
        }
    }

    .sign-file-options-bar {
        padding: 5px;
        background-color: #e5e5e5;
    }
}

.dropdown-item-danger:hover {
    background-color: #cc0000;
    color: #fff;
}

.toast-success {
    background-color: rgba($success, 0.85);
    color: $white;
}

.toast-danger {
    background-color: rgba($danger, 0.85);
    color: $white;
}

.file-preview-sm {
    height: 150px;
    width: 150px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.list-group {
    .list-group-item:not(.d-none) {
        border-top-width: 1px;
    }

    .list-group-item:not(.d-none) ~ .list-group-item:not(.d-none) {
        border-top-width: 0;
    }
}

.toggle-container {
    border: 1px solid red;
}

.whitepace-preline {
    white-space: pre-line;
}

.menu-profile {
    line-height: 30px;
}

.menu-profile-picture {
    border-radius: 50%;
    max-width: 30px;
    max-height: 30px;
    margin-left: 10px;
}

.profile-picture {
    border-radius: 50%;
    max-width: 30px;
    max-height: 30px;
}

.location-photo-container {
    position: relative;
    border: 2px solid #dee2e6;
    overflow: visible;
    height: 150px;
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 100%;
        height: 140px;
        object-fit: contain;
    }

    .dropdown {
        position: absolute;
        top: 5px;
        right: 5px;
    }

    &.expired::after {
        position: absolute;
        bottom: 5px;
        left: 5px;
        background-color: #cc0000;
        color: #fff;
        content: 'EXPIRED';
        padding: 0 4px;
    }

    &.scheduled::after {
        position: absolute;
        bottom: 5px;
        left: 5px;
        background-color: #ffbf00;
        color: #000;
        content: 'SCHEDULED';
        padding: 0 4px;
    }
}

[data-fancybox='gallery'] {
    cursor: pointer;
}

.c2a_mode1 {
    z-index: 1100 !important;
}

.fancybox-thumbs {
    top: auto;
    width: auto;
    bottom: 0;
    left: 0;
    right: 0;
    height: 95px;
    padding: 10px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.3);
}

.fancybox-show-thumbs .fancybox-inner {
    right: 0;
    bottom: 95px;
}

.fancybox-thumbs__list a {
    border-radius: 5px;

    &:before {
        border: 5px solid #4ea7f9;
    }
}

.btn-group-vertical-spaced > button {
    margin-top: unset;
    margin-bottom: 3px !important;
    border-radius: 3px !important;
}

#AppealDocumentPackAccordion {
    #ContraventionPhotoOptions {
        background-color: #f6f6f6;
    }

    #ContraventionPhotosBody,
    #LocationPhotosBody,
    #SignageBody {
        img {
            cursor: pointer;
        }

        .img-overlay {
            display: inline-block;
            position: relative;
            width: 200px;
            height: 150px;
            margin: 0 5px 5px 0;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
            }

            .zoom {
                position: absolute;
                top: 3px;
                right: 3px;
                width: 25px;
                height: 25px;
                background-color: silver;
                content: 'Y';
                text-align: center;
                font-weight: bold;
                display: inline-block;
                color: #000000;
                line-height: 25px;
                cursor: pointer;
            }

            &.selected {
                border: 5px solid blue;

                &::after {
                    position: absolute;
                    bottom: 3px;
                    left: 3px;
                    width: 25px;
                    height: 25px;
                    background-color: blue;
                    content: '\2713';
                    text-align: center;
                    font-weight: bold;
                    display: inline-block;
                    color: #fff;
                    line-height: 25px;
                }
            }
        }
    }
}

.ui-block {
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2000;
}

.presence-container {
    padding: map-get($spacers, 2) $alert-padding-x;
    border: $alert-border-width solid transparent;
    @include border-radius($alert-border-radius);

    $presence: $cyan !default;
    $presence-background: shift-color($presence, $alert-bg-scale);
    $presence-border: shift-color($presence, $alert-border-scale);
    $presence-color: shift-color($presence, $alert-color-scale);

    color: $presence-color;
    @include gradient-bg($presence-background);
    border-color: $presence-border;
    transform: translateX(-50%) !important;
    left: 50% !important;
    bottom: 0 !important;
    position: fixed !important;
    margin-bottom: -4px;
    z-index: 100;
    text-align: center !important;
}

.mapping-notes-tooltip {
    .tooltip-inner {
        white-space: pre-wrap;
        text-align: left;
    }
}

#LocationTimeline {
    .card-body {
        max-height: 600px;
        overflow: auto;
    }
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.55);
}
