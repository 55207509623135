﻿$primary-color: hsl(207, 50%, 50%);
$white: #fff;

div.calendar {
    position: relative;

    span.calendar-month-title {
        font-size: 1.5rem;
    }

    .calendar-overlay {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.4);
        // z-index: $zindex-modal-backdrop;
        content: ' ';
        width: 100%;
        height: 100%;
    }

    .calendar-header {
        position: relative;
    }

    .calendar-body {
        position: relative;

        .calendar-month-picker {
            width: 100%;
            font-family: 'Calibri';
            border: 1px solid darken($primary-color, 10%);
            color: $white;

            .header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                background-color: darken($primary-color, 5%);
                padding: 10px;
                margin: 1px 1px 0 1px;
            }

            .body {
                padding: 1px;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 1px;

                button:disabled {
                    cursor: not-allowed;
                }

                div {
                    background-color: $primary-color;
                    text-align: center;
                    padding: 20px;
                    cursor: pointer;
                    border: 5px solid transparent;

                    &.active {
                        background-color: lighten($primary-color, 10%);
                        text-align: center;
                        padding: 20px;
                        cursor: pointer;
                        border: 5px solid darken($primary-color, 15%);
                    }

                    &.disabled {
                        background-color: hsl(0, 0%, 80%);
                        text-align: center;
                        padding: 20px;
                        color: hsl(0, 0%, 60%);
                        cursor: not-allowed;
                    }

                    &:not(.disabled):not(.active):hover {
                        background-color: lighten($primary-color, 5%);
                    }
                }
            }

            .year-label {
                font-size: 20px;
                font-weight: bold;
            }
        }
    }

    table.calendar {
        width: 100%;
        border: 1px solid silver;

        th,
        td {
            border: 1px solid silver;
        }

        th {
            text-align: center;
        }

        td.calendar-day {
            height: 100px;
            background-color: #e5e5e5 !important;
            width: 14.286%;
            font-size: 12px;
            vertical-align: top;

            &.current-month {
                background-color: white !important;

                &.weekend {
                    background-color: #f6f6f6 !important;
                }
            }

            &.today {
                border: 2px solid #30aadc !important;
            }

            span {
                display: block;
                font-size: 12px;
                text-align: right;
                padding-right: 2px;
                color: grey;
                font-family: Tahoma;
                margin-bottom: 2px;
            }

            div.calendar-item {
                border: 1px solid silver;
                padding: 2px;
                margin: 0 2px 2px 2px;
                font-size: 12px;
                padding: 3px;
            }
        }
    }
}

#LocationContraventionsCalendar,
#ZoneContraventionsCalendar {
    .calendar-item {
        cursor: pointer;
    }

    .calendar-item.contravention-count-warden {
        background-color: #283583;
        color: #fff;
    }

    .calendar-item.contravention-count-anpr {
        background-color: #46b5e4;
        color: #fff;
    }

    .calendar-item.contravention-count-total {
        background-color: #575756;
        color: #fff;
    }
}
